import React from 'react';
import Navbar from "./Navbar";

class Dashboard extends React.Component {

	//constructor( props ) {
		//super( props );
	//}


	render() {
		console.warn( localStorage.getItem( 'token' ) );
		return(
			<React.Fragment>
				<Navbar/>
				<div className="jumbotron" style={{ height: '100vh' }}>
					<h4>Tere! Oled sisse logitud kasutajana <i>{this.props.userName && this.props.userName }</i></h4>
				</div>
			</React.Fragment>
		)
	}
}

export default Dashboard;
