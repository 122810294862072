import React from "react";
import Navbar from "./Navbar";
import axios from "axios";
import Loader from "../loader.gif";
import { Redirect } from "@reach/router";
import clientConfig from "../client-config";
import {FormGroup, FormLabel, TextField} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { } from '@material-ui/icons/Clear'


const handleRowClick = (rowData, rowMeta) => {
    console.log(rowData, rowMeta);
};

const options = {
  filterType: 'checkbox',
  enableNestedDataAccess: ".",
  rowsPerPage: 50,
  rowsPerPageOptions: [25, 50, 200, 500],
  downloadOptions: {
    filterOptions:{ 
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    }
  },
  onRowClick: handleRowClick,
};

class Home extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      posts: [],
      error: "",
      items: [],
      result: [],
      characters: [],
    };
  }
async componentDidMount() {
    const wordPressSiteURL = clientConfig.siteUrl;
    const authToken = localStorage.getItem("token");

    let posts = [];
    let hasMorePages = true;

    for (let pageNumber = 0; hasMorePages; pageNumber++) {
        const params = new URLSearchParams();
        params.append('per_page', 100);
        if (pageNumber > 0) {
            params.append('page', pageNumber);
        }
        
        const url = `${wordPressSiteURL}/wp-json/kbs/v1/tickets?${params}`;
        const result = await axios.get(url, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        });

        posts = [...posts, result.data];

        const postsOnThisPage = result.data.length;
        const totalPages = result.headers["x-wp-totalpages"];
        console.log(`Loaded page ${pageNumber}/${totalPages} which contained ${postsOnThisPage} posts.`)
        
        hasMorePages = postsOnThisPage > 0 && pageNumber < totalPages;
    }

    this.setState({
        loading: false,
        posts,
    });
}

  render() {
// START MUI COLUMS 
const columns = [
  {
    name: "ID",
    label: "ID",
    options: {
      filter: false,
      sort: true,
    },
  },
    {
    name: "Vastuseid",
    label: "Vastuseid",
    options: {
      filter: false,
      sort: true,
    },
  },
      {
    name: "Kategooria ID",
    label: "Kategooria ID",
    options: {
      filter: false,
      sort: true,
    },
  },
        {
    name: "Nõustaja ID",
    label: "Nõustaja ID",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Kuupaev",
    sort: true,
    options: {
      filter: true,
      filterType: "custom",
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1] && this.state.ageFilterChecked) {
            return [`algus: ${v[0]}`, `lõpp: ${v[1]}`];
          } else if (v[0] && v[1] && !this.state.ageFilterChecked) {
            return `algus: ${v[0]}, lõpp: ${v[1]}`;
          } else if (v[0]) {
            return `algus: ${v[0]}`;
          } else if (v[1]) {
            return `lõpp: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          console.log(
            "customFilterListOnDelete: ",
            filterList,
            filterPos,
            index
          );

          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, "");
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        },
      },
      filterOptions: {
        names: [],
        logic(age, filters) {
          if (filters[0] && filters[1]) {
            return age < filters[0] || age > filters[1];
          } else if (filters[0]) {
            return age < filters[0];
          } else if (filters[1]) {
            return age > filters[1];
          }
          return false;
        },
        display: (filterList, onChange, index, column) => (
          <div>
            <FormLabel>Kuupäeva vahemik</FormLabel>
            <FormGroup row>
              <TextField
                label="min"
                value={filterList[index][0] || ""}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: "45%", marginRight: "5%" }}
              />
              <TextField
                label="max"
                value={filterList[index][1] || ""}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: "45%" }}
              />
            </FormGroup>
          </div>
        ),
      },
      print: false,
    },
  },
  {
    name: "Maakond",
    label: "Maakond",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Vanus",
    sort: true,
    options: {
      filter: true,
      filterType: "custom",
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1] && this.state.ageFilterChecked) {
            return [`Min vanus: ${v[0]}`, `Max vanus: ${v[1]}`];
          } else if (v[0] && v[1] && !this.state.ageFilterChecked) {
            return `Min vanus: ${v[0]}, Max vanus: ${v[1]}`;
          } else if (v[0]) {
            return `Min vanus: ${v[0]}`;
          } else if (v[1]) {
            return `Max vanus: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          console.log(
            "customFilterListOnDelete: ",
            filterList,
            filterPos,
            index
          );

          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, "");
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        },
      },
      filterOptions: {
        names: [],
        logic(age, filters) {
          if (filters[0] && filters[1]) {
            return age < filters[0] || age > filters[1];
          } else if (filters[0]) {
            return age < filters[0];
          } else if (filters[1]) {
            return age > filters[1];
          }
          return false;
        },
        display: (filterList, onChange, index, column) => (
          <div>
            <FormLabel>vanus</FormLabel>
            <FormGroup row>
              <TextField
                label="min"
                value={filterList[index][0] || ""}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: "45%", marginRight: "5%" }}
              />
              <TextField
                label="max"
                value={filterList[index][1] || ""}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: "45%" }}
              />
            </FormGroup>
          </div>
        ),
      },
      print: false,
    },
  },
  {
    name: "Sugu",
    label: "Sugu",
    options: {
      filter: true,
      sort: true,
    },
  },
];
// END MUI COLUMS 
    const { loading, posts, error } = this.state;
    console.log(posts);
    if ( localStorage.getItem( 'token' ) ) {
    return (
      <React.Fragment>
        <Navbar />

        {error && (
          <div
            className="alert alert-danger"
            dangerouslySetInnerHTML={this.createMarkup(error)}
          />
        )}
        
        {Object.keys( posts ).length ? (
        <MUIDataTable
          title={"Seksuaaltervis.ee statistikarakendus"}
          data={this.state.posts.flat().map(item => {
                return [
                    item.ticket_data["number"],
                    item.replies,
                    item.ticket_categories,
                    item.ticket_data["agent"],
                    item.date,
                    item.form_data.data["kbs-vali-maakond"],
                    item.form_data.data["kbs-vanus"],
                    item.form_data.data["kbs-vali-sugu"],

                ]
            })}

          columns={columns}
          options={options}
        />
        ) : '' }
        {loading && <div className="loading-wrap"><img className="loader" src={Loader} alt="Loader" /><h3>Laeme andmeid...</h3></div>}
        
      </React.Fragment>
    );
    		
		} else {
		  return ( <Redirect to={`/login`} noThrow /> )
    }
  }
}

export default Home;
